<p mat-dialog-title class="text-xl text-center font-medium text-color-red">
  {{ data.titleKey ? (data.titleKey | translate) : data.title }}
</p>
<div mat-dialog-content>
  <div
    class="grid gap-4 px-2.5 py-3"
    [ngStyle]="{
      'grid-template-columns':
        data.items.length > 12
          ? 'repeat(12, minmax(0, 1fr))'
          : 'repeat(' + data.items.length + ', minmax(0, 1fr))'
    }"
  >
    <div
      *ngFor="let itemElement of data.items"
      class="flex flex-col justify-between flex-nowrap max-w-[10vw] animate__animated animate__fadeInDown"
      [ngClass]="itemElement.disabled ? 'grayscale' : 'cursor-pointer'"
      [matTooltip]="itemElement.disabled ? onDisabledTooltipText : null"
      (click)="!itemElement.disabled && onClick(itemElement)"
    >
      <div
        class="flex flex-col justify-center items-center h-full bg-black p-6 rounded-t-lg"
        [class.opacity-60]="itemElement.disabled"
      >
        <img class="max-w-full w-full" [src]="itemElement.uiImage" alt="" />
      </div>
      <div
        class="flex flex-col justify-center items-center px-2 py-4 bg-gray-800 text-white rounded-b-lg"
        [class.opacity-60]="itemElement.disabled"
      >
        <span class="truncate text-center m-auto">
          {{
            itemElement.nameKey
              ? (itemElement.nameKey | translate)
              : itemElement.name
          }}
        </span>
      </div>
    </div>
  </div>
</div>
