import { BrowserModule } from '@angular/platform-browser';

import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

// import ngx-translate and the http loader
// https://github.com/ngx-translate/core      BabelEdit: https://www.codeandweb.com/babeledit
// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular7-app-with-ngx-translate
// https://youtu.be/k_tpfsrseJ8
// https://medium.com/angular-chile/aplicaciones-multilenguaje-en-angular-7-con-ngx-translate-db8d1e7b380c
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OfflineModule } from '@ngx-pwa/offline';

import { SharedModule } from '@common/shared.module';

import { NotFoundModule } from '@views/not-found/not-found.module';
import { CredentialsModule } from '@views/login/credentials.module';
import { AdminModule } from '@views/admin/admin.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment as env_const } from '@env/environment';

import { MAT_DATE_LOCALE } from '@angular/material/core';

// HttpLoaderFactory is required for AOT (ahead of time) compilation in the project.
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        NotFoundModule,
        CredentialsModule,
        AdminModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            // defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            // https://blog.angular-university.io/angular-service-worker/
            enabled: env_const.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        OfflineModule.forRoot({
            // https://www.npmjs.com/package/@ngx-pwa/offline
            routeOffline: env_const.offlineUrl,
            routeUnavailable: env_const.unavailableUrl,
        }),
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: navigator.language,
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: navigator.language,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
