export const commonEnvironment = {
  adminUser: 'admin',
  environment_tag: '',
  avoid_console_log: false,
  avoid_console_warn: false,
  avoid_console_error: false,
  publicUsername: 'anonymous',
  publicPwd: 'zxcv_1234',
  sendgridServiceEmailApiKey: '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',
  sendgridServiceEmailApiURL: 'https://api.sendgrid.com/v3/mail/send',
  emailjsServiceId: 'service_rejuew3',
  emailjsServiceTemplateId: 'template_bdywzdo',
  emailjsServicePublicKey: 'QIlBa57CVYGNK8VjC',
  emailjsServicePrivateKey: 'jcbWNMuihD3ZZdm12JC3W',
  forcedTitle: '', // 'EDUKA360',
  forcedTitleImage: '',
  production: true,
  clientWebLoginRegisterKey: 'WEB_BROWSER',
  loginAccessRegistration: false,
  loginAccessRegistrationOnTokenRefreshWhenCached: false,
  lobbyWebSocketPrtl: 'wss://',
  lobbyWebSocketPath: 'tiwp-int.tecnalia.com/kayroxassistance/ws/new-calls/',
  lobbyWebSocketUrl:
    'wss://tiwp-int.tecnalia.com/kayroxassistance/ws/new-calls/',
  rtcMultiConnectionUrl: 'https://tiwp-int.tecnalia.com:9001/',
  rtcStunUrl: 'turn:tiwp-int.tecnalia.com:3478',
  rtcStunUsername: 'testuser',
  rtcStunPassword: 'testpassword',
  apiUrl: '/tiwp/rest_api/',
  serverUrl: '',
  publicProjectUrlPrefix: 'https://tiwp-int.tecnalia.com/public/project/',
  defaultLanguage: 'en',
  languages: [
    {
      key: 'es',
      uiImage: 'assets/images/spanish-language.svg',
    },
    {
      key: 'en',
      uiImage: 'assets/images/english-language.svg',
    },
    {
      key: 'eus',
      uiImage: 'assets/images/basque-language.svg',
    },
  ],
  speechRecognitionAvailable: true,
  maxNumberPlmNodeChildren: 1000,
  adminSourceProjectsToBeCloned: [417],
  adminSourceAssetsToBeCloned: [417],
  subscriptionDaysNoticeBoundary: 30,
  userImageAccept: '.jpg, .png, .svg',
  unavailableUrl: '/unavailable',
  offlineUrl: 'offline',
  loginUrl: '/login',
  desktopUrl: '/desktop',
  usageLogPrefixTag: '360EVENT&',
  executionLogsPrefixTags: {
    exp360: '360_LOG_REPORT: ',
    virtualAcademy: 'VR_ACADEMY_LOG_REPORT: ',
    augmentedManual: 'AR_MANUAL_LOG_REPORT: ',
  },
  executionLogsEvents: {
    experienceStart: 'EXPERIENCE_START',
    experienceEnd: 'EXPERIENCE_END',
    experienceExit: 'EXPERIENCE_EXIT',
    firstSceneLoaded: 'FIRST_SCENE_LOADED',
    sceneLoaded: 'SCENE_LOADED',
    stepLoaded: 'STEP_LOADED',
    stepMarked: 'STEP_MARKED',
    questionTagUserOpen: 'QUESTIONTAG_USER_OPEN',
    questionTagTimeOpen: 'QUESTIONTAG_TIME_OPEN',
    questionTagClosed: 'QUESTIONTAG_CLOSED',
    questionTagAnswered: 'QUESTIONTAG_ANSWERED',
    correctAnswered: 'correct',
    incorrectAnswered: 'incorrect',
    ambiguousAnswered: '?',
    geometryQuestionShown: 'GEOMETRY_QUESTION_SHOWN',
    geometryQuestionAnswered: 'GEOMETRY_QUESTION_ANSWERED',
  },
  forceAllProjectsReports: false,
  onlyPublishedProjectReports: true,
  messageKeys: {
    projectBroadcast: 'PROJECT_BROADCAST',
    exp360UseLog: '360EXP_USE_LOG',
    entityUseLog: 'ENTITY_USE_LOG',
  },
  state_i18n_keys: {
    published: 'published',
    edition: 'edition',
    finished: 'finished',
  },
  project_i18n_keys: {
    gaussianSplatting: '3DGSProject',
    experience360: '360experience',
    virtualAcademy: 'virtualAcademy',
    augmentedManual: 'AugmentedManual',
  },
  projectEditUrlPrefix: {
    '360experience': '/desktop/exp360',
    virtualAcademy: '/desktop/editVirtualClassroom',
    AugmentedManual: '/desktop/editAugmentedManual',
    '3DGSProject': '/desktop/editGaussianSplatting'
  },
  projectViewUrlPrefix: {
    '360experience': '/desktop/view360',
    virtualAcademy: '/desktop/viewVirtualClassroom',
    // 'AugmentedManual': '/augmentedManualViewer'
    AugmentedManual: '/desktop/viewAugmentedManual',
    '3DGSProject': '/desktop/viewGaussianSplatting'
  },
  publicViewUrlPrefix: {
    '360experience': '/public/view360',
    virtualAcademy: '/public/viewVirtualClassroom',
    // 'AugmentedManual': '/AugmentedManualViewer'
    AugmentedManual: '/public/viewAugmentedManual',
    '3DGSProject': '/public/viewGaussianSplatting'
  },
  alsinaFont: 'assets/fonts/alsina.fnt',
  arialFont: 'assets/fonts/arial.fnt',
  questionTagBodies: {
    question: 'QUESTION',
    info: 'INFO',
  },
  questionResponseImgs: [
    'assets/icons/questionTag.svg',
    '',
    'assets/icons/questionTagSelect.svg',
    'assets/icons/geoOptions.svg',
    'assets/icons/geoTouch.svg',
  ],
  questionResponseNames: [
    'desktop.questionTagList.textAnswerType',
    'desktop.questionTagList.unknownAnswerType',
    'desktop.questionTagList.screenAnswerType',
    'desktop.questionTagList.optionsAnswerType',
    'desktop.questionTagList.touchAnswerType',
  ],
  useVideoAssetConversion: true,
  produceReportProjectTypeIds: [
    4 /*Experience 360*/, 8 /*Teaching VR*/, 9 /*Manual Aumentado*/, 10 /* GaussianSplatting */
  ],
  availableProjectTypeIds: [
    4 /*Experience 360*/, 8 /*Teaching VR*/, 9 /*Manual Aumentado*/, 10 /* GaussianSplatting */
  ],
  projectTypeIds: {
    exp360: 4,
    virtualAcademy: 8,
    augmentedManual: 9,
    gaussianSplatting: 10
  },
  sceneTypeIds: {
    ar3D: 14,
    image360: 7,
    video360: 8,
    gs3D: 15
  },
  panoramaAssetTypeIds: [5, 12],
  assetTypeIds: {
    pdf: 8,
    asset3d: 4,
    geometryModel: 7,
    audio: 2,
    image360: 12,
    video360: 5,
    video: 1,
    image: 3,
    gaussianSplatting: 13
  },
  assetTypeFileExtensions: {
    asset3d: ['glb', 'fbx', 'zip'],
    geometryModel: ['glb'],
    audio: ['mp3', 'wav'],
    image360: ['jpg', 'png'],
    video360: ['mp4', 'webm'],
    video: ['mp4', 'webm'],
    image: ['jpg', 'png', 'svg'],
    pdf: ['pdf'],
    gaussianSplatting: ['ply', 'splat']
  },
  objectTypeIds: {
    video360: 4,
    questionTag: 5,
    object3D: 6,
    image3D: 7,
    environmentalAudio: 8,
    geometryModel: 9,
    geometryQuestion: 10,
    image360: 11,
    markerImage: 12,
    marker3D: 13,
    sceneNavigator: 14,
    textPanel: 15,
    videoPanel: 16,
    timerObject: 17,
    pdfObject: 18,
    gaussianSplatting: 19,
    camera: 21
  },
  default: {
    accessibilityTypeId: 1, // private
    onNewProject: {
      stateId: 1, // Edición
      sceneId: {
        8: 12, // Academia Virtual : Virtual Classroom
        9: 14, // Manual Aumentado : 3D_AR
        10:15, // GaussianSplatting : 3DGS
      },
    },
    userImg: 'assets/images/user.svg',
    projectStateImg: 'assets/icons/state_type.svg',
    projectImg: 'assets/icons/project_type.svg',
    groupObjectTypeIco: 'assets/icons/object_types/group.svg',
    assetTypeIcon: {
      1: 'assets/icons/asset_types/Asset_video.svg' /*video*/,
      2: 'assets/icons/asset_types/Asset_Audio.svg' /*audio*/,
      3: 'assets/icons/asset_types/image.svg' /*image*/,
      4: 'assets/icons/asset_types/cube.svg' /*asset3d*/,
      5: 'assets/icons/asset_types/video360.svg' /*video360*/,
      8: 'assets/icons/asset_types/PDF_file_icon.svg' /*Documento PDF*/,
      12: 'assets/icons/asset_types/imagen360.svg' /*image360*/,
      13: 'assets/icons/asset_types/gaussian_splatting_tedi.png' /*Gaussian Splatting*/,
      10: 'assets/icons/asset_types/video_editor.svg' /*Editora video*/,
    },
    projectTypeIcon: {
      4: 'assets/icons/project_types/environment360.svg' /*Experience 360*/,
      6: 'assets/icons/project_types/virtual-reality.svg' /*Virtual Reality*/,
      8: 'assets/icons/project_types/vr-academy.svg' /*Teaching VR*/,
      9: 'assets/icons/project_types/ARmanual.svg' /*Manual Aumentado*/,
      10: 'assets/icons/project_types/gaussiansplatting.png' /*Manual Aumentado*/,
    },
    sceneImg: 'assets/icons/scene_type.svg',
    assetImg: 'assets/icons/asset_type.svg',
    objectImg: 'assets/icons/object_type.svg',
  },
  roles: {
    admin: 'Administrador',
    editor: 'Creador',
    consumer: 'Consumidor',
  },
  rolesOnListColor: {
    Administrador: 3,
    Creador: 5,
    Consumidor: 2,
  },
  byRoleAccessibilityTypeIdOrder: {
    asset: {
      Administrador: [1, 2, 3],
      Creador: [1, 2, 3],
      Freemium: [1, 3],
      Consumidor: [],
    },
    project: {
      Administrador: [1, 2, 4, 3],
      Creador: [1, 2, 4, 3],
      Freemium: [1, 3],
      Consumidor: [4, 3],
    },
  },
  accessibilityTypeIds: {
    private: 1,
    public: 3,
    organization: 4,
    development: 2,
  },
  publishingUserOptionsImgs: {
    overwrite: 'assets/icons/overwrite.svg',
    newone: 'assets/icons/newone.svg',
  },
  itemImg: 'assets/images/item.svg',
  assetImg: 'assets/images/asset_type.svg',
  projectImg: 'assets/images/project_type.svg',
  pdfHelpUrl: {
    es: '/media/uploads/KAYROX_manual_es.pdf',
    en: '/media/uploads/KAYROX_manual_en.pdf',
    eus: '/media/uploads/KAYROX_manual_es.pdf',
  },
  pdfCad2GlbUrl: {
    es: '/media/uploads/CAD2GLB_tips.pdf',
    en: '/media/uploads/CAD2GLB_tips.pdf',
    eus: '/media/uploads/CAD2GLB_tips.pdf',
  },
};
