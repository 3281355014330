import { Component, Inject } from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { TranslateService } from '@ngx-translate/core';

import { ImageSelectionDialogData, ImageFileModel } from './models/image-selection-dialog-data';

@Component({
  selector: 'tiwp-item-selection-dialog',
  templateUrl: './image-selection-dialog.component.html',
  styleUrls: ['./image-selection-dialog.component.scss']
})
export class ImageSelectionDialogComponent {
  onDisabledTooltipText = 'disabled';
  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ImageSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageSelectionDialogData
  ) {
    this.translate.get('common.tooltip.disabled').subscribe(
      (tooltipText: string) => (this.onDisabledTooltipText = tooltipText),
      (error) => {
        this.onDisabledTooltipText = 'disabled';
        console.error(
          '%cLanguage error: ',
          'color:black;background:red',
          error.message,
          'Key: common.tooltip.disabled'
        );
      }
    );
  }

  onClick(itemSelected: ImageFileModel): void {
    this.dialogRef.close(itemSelected);
  }
}
