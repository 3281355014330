import {Injectable, NgZone} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {Observable, of, take} from 'rxjs';
import {ItemSelectionDialogComponent} from '@common/dialogs/item-selection-dialog/item-selection-dialog.component';
import {ConfirmationDialogComponent} from '@common/dialogs/confirmation-dialog/confirmation-dialog.component';
import {PublicLinkDialogComponent} from '@common/dialogs/public-link-dialog/public-link-dialog.component';
import {MessageDialogComponent} from '@common/dialogs/message-dialog/message-dialog.component';
import {PlayDialogComponent} from '@common/dialogs/play-dialog/play-dialog.component';
import {SpinnerDialogComponent} from '@common/dialogs/spinner-dialog/spinner-dialog.component';
import {SelectionType} from '@common/models/types-model';
import {SortItemsDialogComponent} from '@common/dialogs/sort-items-dialog/sort-items-dialog.component';
import {CheckUserDialogComponent} from '@common/dialogs/check-user-dialog/check-user-dialog.component';
import {ChangeUserPwdDialogComponent} from '@common/dialogs/change-user-pwd-dialog/change-user-pwd-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {environment as env_const} from '@env/environment';
import {TokenResponseModel} from '@common/models/response-model';
import {SelectListDialogComponent} from '@common/dialogs/select-list-dialog/select-list-dialog.component';
import {TreeNodeModel} from '@common/models/id-name-model';
import {FolderSelectionDialogComponent} from '@common/dialogs/folder-selection-dialog/folder-selection-dialog.component';
import {ImageFileModel} from '@common/dialogs/image-selection-dialog/models/image-selection-dialog-data';
import {ImageSelectionDialogComponent} from '@common/dialogs/image-selection-dialog/image-selection-dialog.component';
import {catchError, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonDialogsService {
  private _spinner: MatDialogRef<SpinnerDialogComponent>;

  constructor(
    private translate: TranslateService,
    private dialog:  MatDialog,
    private ngZone: NgZone
  ) { }

  public showTranslatedPlayDialog$ (messageTranslatedTextKey?: string ): Observable<boolean> {
    const dialogRef = this.dialog.open( PlayDialogComponent, {
      data: {
        messageTranslatedTextKey: messageTranslatedTextKey
      }
    } );

    return dialogRef.afterClosed();
  }

  public openTranslatedConfirmationDialog$ (
    messageKey: string, btnOkLabelKey?: string, btnCancelLabelKey?: string, params?: Object
  ): Observable<boolean> {
    const dialogRef = this.dialog.open( ConfirmationDialogComponent, {
      data: {
        questionKey: messageKey,
        questionParams: params,
        btnOkLabelKey: btnOkLabelKey || 'desktop.labels.okButton',
        btnCancelLabelKey: btnCancelLabelKey || 'desktop.labels.cancelButton'
      }
    } );

    return dialogRef.afterClosed();
  }

  public openTranslatedPublicLinkDialog$ (
    messageKey: string, btnOkLabelKey?: string, btnCancelLabelKey?: string, params?: { url: string; }
  ): Observable<boolean> {
    const dialogRef = this.dialog.open( PublicLinkDialogComponent, {
      data: {
        questionKey: messageKey,
        url: params.url,
        btnOkLabelKey: btnOkLabelKey || 'desktop.labels.okButton',
        btnCancelLabelKey: btnCancelLabelKey || 'desktop.labels.cancelButton'
      }
    } );

    return dialogRef.afterClosed();
  }

  public openConfirmationDialog$ (
    question: string, btnOkLabel?: string, btnCancelLabel?: string, title?: string
  ): Observable<any> {
    const dialogRef = this.dialog.open( ConfirmationDialogComponent, {
      data: {
        question: question,
        title: title || '',
        btnOkLabel: btnOkLabel || 'Ok',
        btnCancelLabel: btnCancelLabel || 'Cancel'
      }
    } );

    return dialogRef.afterClosed();
  }

  public openTranslatedCheckUserDialog$ (
    labelKey: string, btnCheckLabelKey: string, btnCancelLabelKey: string
  ): Observable<boolean> {
    return new Observable<boolean>(( observer ) => {
      const onGettingMessageError = ( error ) => {
        observer.error(error);
        console.error(
          '%cLanguage error: ', 'color:black;background:red',
          error.message, `Keys: [${labelKey}, ${btnCheckLabelKey}, ${btnCancelLabelKey}]`
        );
      };

      let dialogCloseSubscription;
      const onGetTexts = ( texts: Object ) => {
        const dialogRef = this.dialog.open( CheckUserDialogComponent, {
          data: {
            label: texts[labelKey],
            btnCheckLabel: texts[btnCheckLabelKey],
            btnCancelLabel: texts[btnCancelLabelKey]
          }
        } );

        const dialogOpened = dialogRef.afterClosed();
        dialogCloseSubscription = dialogOpened.subscribe(
          ( result: boolean ) =>  {
            dialogCloseSubscription.unsubscribe();
            observer.next( result );
          },
          onGettingMessageError
        );
      };

      this.translate.get( [labelKey, btnCheckLabelKey, btnCancelLabelKey] ).subscribe(
        onGetTexts, onGettingMessageError
      );

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          if ( dialogCloseSubscription ) {
            dialogCloseSubscription.unsubscribe();
          }
        }
      };
    } );
  }

/*
  public openCheckUserDialog$ (
    label: string, btnCheckLabel?: string, btnCancelLabel?: string
  ): Observable<boolean> {
    const dialogRef = this.dialog.open( CheckUserDialogComponent, {
      data: {
        label: label || 'Password',
        btnCheckLabel: btnCheckLabel || 'Check',
        btnCancelLabel: btnCancelLabel || 'Cancel'
      }
    } );

    return dialogRef.afterClosed();
  }
*/

  public openTranslatedChangeUserPasswordDialog$ (
    userId: number, username: string,
    pwdLabelKey: string, confirmPwdLabelKey: string,
    btnOkLabelKey: string, btnCancelLabelKey: string
  ): Observable<TokenResponseModel> {
    return new Observable<TokenResponseModel>(( observer ) => {
      const onGettingMessageError = ( error ) => {
        observer.error(error);
        console.error(
          '%cLanguage error: ', 'color:black;background:red',
          error.message, `Keys: [${pwdLabelKey}, ${confirmPwdLabelKey}, ${btnOkLabelKey}, ${btnCancelLabelKey}]`
        );
      };

      let dialogCloseSubscription;
      const onGetTexts = ( texts: Object ) => {
        const dialogRef = this.dialog.open( ChangeUserPwdDialogComponent, {
          data: {
            userId: userId,
            username: username,
            pwdLabel: texts[pwdLabelKey],
            confirmPwdLabel: texts[confirmPwdLabelKey],
            btnOkLabel: texts[btnOkLabelKey],
            btnCancelLabel: texts[btnCancelLabelKey]
          }
        } );

        const dialogOpened = dialogRef.afterClosed();
        dialogCloseSubscription = dialogOpened.subscribe(
          ( result: TokenResponseModel ) =>  {
            dialogCloseSubscription.unsubscribe();
            observer.next( result );
          },
          onGettingMessageError
        );
      };

      this.translate.get( [pwdLabelKey, confirmPwdLabelKey, btnOkLabelKey, btnCancelLabelKey] ).subscribe(
        onGetTexts, onGettingMessageError
      );

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          if ( dialogCloseSubscription ) {
            dialogCloseSubscription.unsubscribe();
          }
        }
      };
    } );
  }

/*
  public openChangeUserPasswordDialog$ (
    userId: number, username: string, pwdLabel: string, confirmPwdLabel: string, btnOkLabel?: string, btnCancelLabel?: string
  ): Observable<any> {
    const dialogRef = this.dialog.open( ChangeUserPwdDialogComponent, {
      data: {
        userId: userId,
        username: username,
        pwdLabel: pwdLabel || 'New password',
        confirmPwdLabel: confirmPwdLabel || 'Confirm password',
        btnOkLabel: btnOkLabel || 'Change',
        btnCancelLabel: btnCancelLabel || 'Cancel'
      }
    } );

    return dialogRef.afterClosed();
  }
*/

  public openTranslatedSelectFromItemListDialog$ (
    messageKey: string, items: {name: string}[], btnCancelLabelKey: string, params?: Object
  ): Observable<{name: string}> {
    const dialogRef = this.dialog.open( SelectListDialogComponent, {
      data: {
        messageKey: messageKey,
        messageParams: params,
        itemList: items,
        btnCancelLabelKey: btnCancelLabelKey
      }
    } );

    return dialogRef.afterClosed();
  }

  public openTranslatedSortItemsDialog$ (
    messageKey: string, items: any[], btnOkLabelKey: string, btnCancelLabelKey: string, params?: Object
  ): Observable<any[]> {
    const dialogRef = this.dialog.open( SortItemsDialogComponent, {
      data: {
        messageKey: messageKey,
        messageParams: params,
        items: items,
        btnOkLabelKey: btnOkLabelKey,
        btnCancelLabelKey: btnCancelLabelKey
      }
    } );

    return dialogRef.afterClosed();
  }

  private translateSelectTypeDialog$ (typesToSelect: SelectionType[], titleKey: string, params: Object): Observable<SelectionType> {
    return new Observable<SelectionType>(( observer ) => {
      const onGettingMessageError = ( error ) => {
        observer.error(error);
        console.error('%cLanguage error: ', 'color:black;background:red', error.message, 'Key: ' + titleKey );
      };

      let dialogCloseSubscription;
      const onGetMessage = ( title: string ) => {
        const dialogRef = this.dialog.open( ItemSelectionDialogComponent, {
          data: {
            title: title,
            items: typesToSelect
          }
        });

        const dialogOpened = dialogRef.afterClosed();
        dialogCloseSubscription = dialogOpened.subscribe(
          ( result ) =>  {
            dialogCloseSubscription.unsubscribe();
            observer.next( result );
          },
          onGettingMessageError
        );
      };

      this.translate.get( titleKey, params ).subscribe( onGetMessage, onGettingMessageError );

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          if ( dialogCloseSubscription ) {
            dialogCloseSubscription.unsubscribe();
          }
        }
      };
    } );
  }

  public openSelectTypeDialog$( typesToSelect: SelectionType[], titleKey: string, params?: Object): Observable<SelectionType> {
    if ( !params ) {
      const dialogRef = this.dialog.open( ItemSelectionDialogComponent, {
        data: {
          titleKey: titleKey,
          items: typesToSelect
        }
      });

      return dialogRef.afterClosed();
    } else {
      return this.translateSelectTypeDialog$ (typesToSelect, titleKey, params);
    }
  }

  private translateImageFileDialog$(
    imageFilesToSelect: ImageFileModel[],
    titleKey: string,
    params: Object
  ): Observable<ImageFileModel> {
    return this.translate.get(titleKey, params).pipe(
      catchError((error) => {
        console.error('%cLanguage error:', 'color:black;background:red', error.message, 'Key:', titleKey);
        return of(titleKey); // Usa el título original en caso de error
      }),
      switchMap((title: string) => {
        const dialogRef = this.dialog.open(ImageSelectionDialogComponent, {
          data: { title, imageFiles: imageFilesToSelect },
        });

        return dialogRef.afterClosed().pipe(take(1));
      })
    );
  }

  public openSelectImageFileDialog$(
    imageFilesToSelect: ImageFileModel[],
    titleKey: string,
    params?: Object
  ): Observable<ImageFileModel> {
    return params
      ? this.translateImageFileDialog$(imageFilesToSelect, titleKey, params)
      : this.dialog
        .open(ImageSelectionDialogComponent, {
          data: { titleKey, imageFiles: imageFilesToSelect },
        })
        .afterClosed()
        .pipe(take(1));
  }

  private translateSelectFolderDialog$ (
    folders: TreeNodeModel, selectedFolder: TreeNodeModel, titleKey: string, params: Object
  ): Observable<TreeNodeModel> {
    return new Observable<TreeNodeModel>(( observer ) => {
      const onGettingMessageError = ( error ) => {
        observer.error(error);
        console.error('%cLanguage error: ', 'color:black;background:red', error.message, 'Key: ' + titleKey );
      };

      let dialogCloseSubscription;
      const onGetMessage = ( title: string ) => {
        const dialogRef = this.dialog.open( FolderSelectionDialogComponent, {
          data: {
            title: title,
            selectedFolder: selectedFolder,
            folders: folders
          }
        });

        const dialogOpened = dialogRef.afterClosed();
        dialogCloseSubscription = dialogOpened.subscribe(
          ( result ) =>  {
            dialogCloseSubscription.unsubscribe();
            observer.next( result );
          },
          onGettingMessageError
        );
      };

      this.translate.get( titleKey, params ).subscribe( onGetMessage, onGettingMessageError );

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          if ( dialogCloseSubscription ) {
            dialogCloseSubscription.unsubscribe();
          }
        }
      };
    } );
  }

  public openSelectFolderDialog$(
    folders: TreeNodeModel, selectedFolder: TreeNodeModel, titleKey: string, params?: Object
  ): Observable<TreeNodeModel> {
    if ( !params ) {
      const dialogRef = this.dialog.open( FolderSelectionDialogComponent, {
        data: {
          titleKey: titleKey,
          selectedFolder: selectedFolder,
          folders: folders
        }
      } );

      return dialogRef.afterClosed();
    } else {
      return this.translateSelectFolderDialog$ (folders, selectedFolder, titleKey, params);
    }
  }

  private translateMessageDialog$ (messageKey: string, params: Object, holdtime?: number): Observable<string> {
    const observable = new Observable<string>((observer) => {
      const onGettingMessageError = ( error ) => {
        observer.error(error);
        console.error('%cLanguage error: ', 'color:black;background:red', error.message, 'Key: ' + messageKey );
      };

      let dialogCloseSubscription;
      const onGetMessage = ( text: string ) => {
        const dialogRef = this.dialog.open( MessageDialogComponent, {
          data: {
            message: text,
            remainTime: holdtime
          },
          restoreFocus: false
        } );

        const dialogOpened = dialogRef.afterClosed();
        dialogCloseSubscription = dialogOpened.subscribe(
          ( /*result*/ ) =>  {
            dialogCloseSubscription.unsubscribe();
            observer.next( text );
          },
          onGettingMessageError
        );
      };

      this.translate.get( messageKey, params ).subscribe( onGetMessage, onGettingMessageError );

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          if ( dialogCloseSubscription ) {
            dialogCloseSubscription.unsubscribe();
          }
        }
      };
    } );

    const subscription = observable.subscribe(
      (/*text*/) => subscription.unsubscribe(),
      (/*error*/) => subscription.unsubscribe()
    );
    return observable;
  }

  public openTranslatedMessageDialog$ ( messageKey: string, params?: Object, holdtime?: number): Observable<any> {
    if ( !params ) {
      const dialogRef = this.dialog.open( MessageDialogComponent, {
        data: {
          message: '',
          messageKey: messageKey,
          remainTime: holdtime
        },
        restoreFocus: false
      } );

      return dialogRef.afterClosed();
    } else {
      return this.translateMessageDialog$ (messageKey, params, holdtime);
    }
  }

  public openMessageDialog$ (message: string, holdtime?: number): Observable<any> {
    const dialogRef = this.dialog.open( MessageDialogComponent, {
        data: {
          message: message,
          remainTime: holdtime
        }
    } );

    return dialogRef.afterClosed();
  }

  private openSpinnerDialog (
    title: string, initialPercent?: number, timeout?: number, veryLongTime?: boolean
  ): MatDialogRef<SpinnerDialogComponent> {
    let mode;
    if ( initialPercent === undefined || initialPercent === null ) {
      mode = 'indeterminate';
    } else {
      mode = 'determinate';
    }

    return this.dialog.open( SpinnerDialogComponent, {
      data: {
        timeout: timeout,
        mode: mode,
        initialAmount: initialPercent,
        title: title,
        veryLongTime: veryLongTime
      }
    });
  }

/*
  public setAmountInSpinnerDialog ( dialog: MatDialogRef<SpinnerDialogComponent>, percent: number ) {
    dialog.componentInstance.setAmount(percent);
  }

  public closeSpinnerDialog ( dialog: MatDialogRef<SpinnerDialogComponent> ) {
    dialog.componentInstance.closeMe();
  }
*/

  public showTranslatedSpinner (
    messageKey?: string, initialPercent?: number, timeout?: number, veryLongTime?: boolean, params?: Object
  ) {
    if ( messageKey ) {
      this.translate.get( messageKey, params ).subscribe(
        text => {
          this.showSpinner(text, initialPercent, timeout, veryLongTime);
        },
        error => {
          throw new Error( error || 'Error: Fail getting translated text' );
        }
      );
    } else {
      this.showSpinner( );
    }
  }

  public showSpinner ( shortMessage?: string, initialPercent?: number, timeout?: number, veryLongTime?: boolean ) {
    if ( this._spinner && this._spinner.componentInstance ) {
      this._spinner.componentInstance.updateTitle( shortMessage );
    } else {
      this._spinner = this.openSpinnerDialog( shortMessage || '', initialPercent, timeout, veryLongTime );
    }
  }

  public setSpinnerProgress ( percent: number ) {
    if ( this._spinner && this._spinner.componentInstance ) {
      this.ngZone.run(
        () => {
          if ( this._spinner && this._spinner.componentInstance ) {
            this._spinner.componentInstance.setAmount(percent);
          }
        }
      );
    }
  }

  public hideSpinner () {
    if (this._spinner && this._spinner.componentInstance ) {
      this.ngZone.run(
        () => {
          if ( this._spinner && this._spinner.componentInstance ) {
            this._spinner.componentInstance.closeMe();
            this._spinner = undefined;
          }
        }
      );
    }
  }

  private getLanguageSelectionList(): SelectionType[] {
    const languagesToSelect: SelectionType[] = [];

    const languages = <{key: string, uiImage: string}[]>env_const.languages;
    const prefix = 'languageName.';
    languages.forEach(
      ( language ) => {
        languagesToSelect.push(
          {
            name: '',
            nameKey: prefix + language.key,
            uiImage: language.uiImage,
            id: language.key
          }
        );
      }
    );

    return languagesToSelect;
  }

  public openLanguageSelectionDialog() {
    const languagesToSelect: SelectionType[] = this.getLanguageSelectionList();

    this.openSelectTypeDialog$(
      languagesToSelect, 'common.labels.selectLanguage'
    ).subscribe(
      (selectedLanguages) => {
        if (selectedLanguages && selectedLanguages.id ) {
          // set the language selection
          this.translate.use( selectedLanguages.id.toString() );
        }
      }
    );
  }
}
