<p mat-dialog-title class="text-xl text-center font-medium text-color-red">
  {{ data.titleKey ? (data.titleKey | translate) : data.title }}
</p>

<div>
  <!-- Contenedor del grid que se ajusta en ancho -->
  <div
    class="grid gap-8 px-0 py-0"
    style="width: 100%; display: grid; grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));"
  >
    <div
      *ngFor="let imageFile of data.imageFiles"
      class="flex justify-center items-center animate__animated animate__fadeInDown"
      (click)="onClick(imageFile)"
    >
      <div class="flex justify-center items-center bg-white p-2 rounded-t-lg">
        <img
          class="max-w-[64px] max-h-[64px] bg-hover-color cursor-pointer"
          [src]="imageFile.file"
          alt="Image"
        />
      </div>
    </div>
  </div>
</div>
