// https://coryrylan.com/blog/subscribing-to-multiple-observables-in-angular-components
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {environment as env_const} from '@env/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AssetModel, NewUser, ObjectEditModel, TiwpSceneObjectModel, Viewers} from '@common/models/types-model';
import {StoreService} from '@common/services/store.service';
import {TiwpItem} from '@common/models/item-model';
import {IdNameModel, TypeModel} from '@common/models/id-name-model';
import {ComponentsModel} from '@common/models/component-types-model';
import {
  AssetsResponseModel,
  ObjectResponseModel,
  ProjectResponseModel,
  ProjectsResponseModel,
  UserResponseModel,
  UsersResponseModel,
  SceneResponseModel,
  FolderResponseModel,
  ChangeFolderResponseModel,
  ProjectPublicationResponseModel,
  UserCapabilityResponseModel, SceneTypesResponseModel, ProjectScenesResponseModel,
} from '@common/models/response-model';

import {ComponentsTranslator} from '@views/desktop/components-translator';

const isFilled = (object): boolean => {
  return Object.keys(object).length !== 0;
};

const secureQueryNumberAppend = (queryData: FormData, key: string, value: number): boolean => {
  if (value !== undefined && value !== null && !Number.isNaN(value)) {
    queryData.append(key, value.toString());
    return true;
  } else {
    return false;
  }
};

const secureQueryBooleanAppend = (queryData: FormData, key: string, value: boolean): boolean => {
  if (value !== undefined) {
    queryData.append(key, value.toString());
    return true;
  } else {
    return false;
  }
};

const secureQueryStringAppend = (queryData: FormData, key: string, value: string): boolean => {
  if (value !== undefined) {
    queryData.append(key, value);
    return true;
  } else {
    return false;
  }
};

const secureQueryAppend = (queryData: FormData, key: string, value: any): boolean => {
  if (value) {
    queryData.append(key, value.toString());
    return true;
  } else {
    return false;
  }
};

const secureQueryObjectAppend = (queryData: FormData, key: string, object: any): boolean => {
  if (object) {
    queryData.append(key, JSON.stringify(object));
    return true;
  } else {
    return false;
  }
};

const getServerUrl = ( ): string => {
  return env_const.serverUrl || window.location.origin;
};

@Injectable({
  providedIn: 'root'
})
export class DesktopRestService {
  private static readonly HEADERS = new HttpHeaders({'Content-Type': 'application/json'});

  private apiUrl = env_const.apiUrl;

  private _assetTypes: Array<TypeModel>;
  // private _projectTypes: Array<TypeModel>;
  private componentsTranslator: ComponentsTranslator;

  constructor(private store: StoreService, private http: HttpClient) {
    this.subscribeToGetTypes();

    this.componentsTranslator = new ComponentsTranslator(store);
  }

  public static getAssetViewer(asset: AssetModel, assetTypes: Array<TypeModel>, panoramaIds: number[]): Viewers {
    let viewer = Viewers.none;

    let accept = '';

    let assetType;
    const asset_type = asset && (asset.type || asset.asset_type_id);
    if (asset_type && assetTypes && assetTypes.length) {
      assetType = assetTypes.find(type => type.id === asset_type.id);
      if (assetType) {
        accept = assetType.accept;
      }
    }

    if (asset.file && DesktopRestService.getFileExtension(asset.file) === 'fbx') {
      viewer = Viewers.fbx;
    } else if (assetType && accept) {
      if (panoramaIds.indexOf(assetType.id) !== -1) {
        viewer = Viewers.panorama;
      } else if (accept.includes('fbx')) {
        viewer = Viewers.glb;
      } else if (accept.includes('glb')) {
        viewer = Viewers.glb;
      } else if (accept.includes('mp4') || accept.includes('webm')) {
        viewer = Viewers.video;
      } else if (accept.includes('mp3')) {
        viewer = Viewers.audio;
      } else if (accept.includes('jpg') || accept.includes('png') || accept.includes('svg')) {
        viewer = Viewers.image;
      } else if (accept.includes('pdf')) {
        viewer = Viewers.pdf;
      } else if (accept.includes('ply') || accept.includes('splat')) {
        viewer = Viewers.gaussianSplatting;
      }
    }

    return viewer;
  }

  public static getAssetType(asset: AssetModel, assetTypes: Array<TypeModel>): TypeModel {
    const getAssetTypeId = (assetIdName: IdNameModel) => {
      if (assetIdName.id === env_const.assetTypeIds.geometryModel) {
        return env_const.assetTypeIds.asset3d;
      } else {
        return assetIdName.id;
      }
    };

    let assetType: TypeModel;
    const asset_type_id = asset && getAssetTypeId(asset.type || asset.asset_type_id);
    if (asset_type_id && assetTypes && assetTypes.length) {
      assetType = assetTypes.find(type => type.id === asset_type_id);
    }
    return assetType;
  }

  public static getFileExtension(filename: string): string {
    const fileExtension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || '';
    return fileExtension.trim().toLowerCase();
    // return filename.slice((Math.max(0, filename.lastIndexOf('.')) || Infinity) + 1);
    // return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
  }

  public static getAssetTypeImage(assetTypes: Array<TypeModel>, assetTypeId: number): string {
    let image = env_const.default.assetTypeIcon[assetTypeId];

    if (!image) {
      // https://stackoverflow.com/questions/40306927/find-object-by-its-property-in-array-of-objects-with-angular-way
      const typeDefinition = assetTypes.find(type => type.id === assetTypeId);
      if (typeDefinition && typeDefinition.icon) {
        image = getServerUrl() + typeDefinition.icon;
      }
    }

    if (!image) {
      image = getServerUrl() + '/' + env_const.default.assetImg;
    }

    return image;
  }

  public static getProjectTypeImage(types: Array<TypeModel>, typeId: number): string {
    let image: string;
    // https://stackoverflow.com/questions/40306927/find-object-by-its-property-in-array-of-objects-with-angular-way
    const typeDefinition = types.find(type => type.id === typeId);
    if (typeDefinition && typeDefinition.icon) {
      image = getServerUrl() + typeDefinition.icon;
    } else {
      image = env_const.default.projectImg;
    }

    return image;
  }

  public static getProjectTypeIcon(typeId: number): string {
    let image = env_const.default.projectTypeIcon[typeId];
    if (!image) {
      image = env_const.default.projectImg;
    }

    return image;
  }

  public static getSceneTypeImage(types: Array<TypeModel>, typeId: number): string {
    let image: string;
    // https://stackoverflow.com/questions/40306927/find-object-by-its-property-in-array-of-objects-with-angular-way
    const typeDefinition = types.find(type => type.id === typeId);
    if (typeDefinition && typeDefinition.icon) {
      image = getServerUrl() + typeDefinition.icon;
    } else {
      image = env_const.default.sceneImg;
    }

    return image;
  }

  public static getNowDateTime(): string {
    const get2DigitsString = (value): string => ((value < 10) ? '0' : '') + value;
    const now = new Date();
    /*    const datetime = now.getUTCFullYear() + '/' + (now.getUTCMonth() + 1) + '/' + now.getUTCDate() + ' ' +
                             now.getUTCHours() + ':' + now.getUTCMinutes() + ':' + now.getUTCSeconds();
    */
    // date: string ('yyyy-MM-dd hh:mm:ss')
    return now.getFullYear() + '-' +
      get2DigitsString((now.getMonth() + 1)) + '-' +
      get2DigitsString(now.getDate()) + ' ' +
      get2DigitsString(now.getHours()) + ':' +
      get2DigitsString(now.getMinutes()) + ':' +
      get2DigitsString(now.getSeconds());
  }

  private subscribeToGetTypes() {
    this.store.getAssetTypes$().subscribe(this.setAssetTypes.bind(this));
    // this.store.getProjectTypes$().subscribe(this.setProjectTypes.bind(this));
  }

  private setAssetTypes(assetTypes: Array<TypeModel>) {
    this._assetTypes = assetTypes;
  }

  /*
    private setProjectTypes(projectTypes: Array<TypeModel>) {
      this._projectTypes = projectTypes;
    }
  */

  public editFolder$(folderId: number, name?: string, parentId?: number): Observable<FolderResponseModel> {
    if (name || (parentId !== undefined && parentId !== null)) {
      const restServiceUrl = this.apiUrl + 'edit_folder/';
      const queryData = new FormData();
      queryData.set('folder_id', folderId.toString());
      if (name) {
        queryData.set('name', name);
      }
      if (parentId !== undefined && parentId !== null) {
        queryData.set('parent_id', parentId.toString());
      }

      return this.http.post<FolderResponseModel>(restServiceUrl, queryData);
    } else {
      throw new Error('Error: Invalid use of edit_folder REST service');
    }
  }

  public addFolder$(parentId: number, name: string): Observable<FolderResponseModel> {
    const restServiceUrl = this.apiUrl + 'add_folder/';
    const queryData = new FormData();
    queryData.set('parent_id', parentId.toString());
    queryData.set('name', name);

    return this.http.post<FolderResponseModel>(restServiceUrl, queryData);
  }

  public deleteFolder$(folderId: number): Observable<FolderResponseModel> {
    const restServiceUrl = this.apiUrl + 'delete_folder/';
    const queryData = new FormData();
    queryData.set('folder_id', folderId.toString());

    return this.http.post<FolderResponseModel>(restServiceUrl, queryData);
  }

  public changeAssetsFolder$(assetIds: number[], folderId: number): Observable<ChangeFolderResponseModel> {
    const restServiceUrl = this.apiUrl + 'edit_assets_folder/';
    const params = new HttpParams().appendAll({
      'asset_ids': assetIds,
      'folder_id': folderId
    });
    return this.http.post<ChangeFolderResponseModel>(restServiceUrl, null, {
      headers: DesktopRestService.HEADERS,
      params: params
    });
  }

  public changeProjectsFolder$(projectIds: number[], folderId: number): Observable<ChangeFolderResponseModel> {
    const restServiceUrl = this.apiUrl + 'edit_projects_folder/';
    const params = new HttpParams().appendAll({
      'project_ids': projectIds,
      'folder_id': folderId
    });
    return this.http.post<ChangeFolderResponseModel>(restServiceUrl, null, {
      headers: DesktopRestService.HEADERS,
      params: params
    });
  }

  public storeAssetName$(item: TiwpItem): Observable<any> {
    if (item.asset) {
      const restServiceUrl = this.apiUrl + 'edit_asset_name/';
      const queryData = new FormData();
      queryData.append('asset_id', item.asset.id.toString());
      queryData.append('name', item.asset.name);

      return this.http.post<any>(restServiceUrl, queryData);
    }
  }

  public storeProjectName$(item: TiwpItem): Observable<any> {
    if (item.project) {
      const restServiceUrl = this.apiUrl + 'edit_project/';
      const queryData = new FormData();
      queryData.append('project_id', item.project.id.toString());
      queryData.append('name', item.project.name);

      return this.http.post<any>(restServiceUrl, queryData);
    }
  }

  public cloneProject$(projectId: number, folderId: number): Observable<ProjectResponseModel> {
    if (projectId && folderId) {
      const restServiceUrl = this.apiUrl + 'clone_project/';
      const queryData = new FormData();
      queryData.append('project_id', projectId.toString());
      queryData.append('folder_id', folderId.toString());

      return this.http.post<ProjectResponseModel>(restServiceUrl, queryData);
    }
  }

  public publishProject$(
    projectId: number,
    folderId: number,
    overwriteProjectPublishedId?: number,
    forceNewPublishing?: boolean
  ): Observable<ProjectPublicationResponseModel> {
    if (projectId && folderId) {
      const restServiceUrl = this.apiUrl + 'publish_project/';
      const queryData = new FormData();
      queryData.append('project_id', projectId.toString());
      queryData.append('folder_id', folderId.toString());

      secureQueryNumberAppend(queryData, 'overwrite_project_id', overwriteProjectPublishedId);
      secureQueryAppend(queryData, 'force', forceNewPublishing);

      return this.http.post<ProjectPublicationResponseModel>(restServiceUrl, queryData);
    }
  }

  private postDeleteAssets(params: HttpParams): Observable<any> {
    const restServiceUrl = this.apiUrl + 'delete_assets/';
    return this.http.post<any>(restServiceUrl, null, {
      headers: DesktopRestService.HEADERS,
      params: params
    });
  }

  public deleteAssets$(assetIds: number[]): Observable<any> {
    const params = new HttpParams().appendAll({
      'asset_ids': assetIds
    });
    return this.postDeleteAssets(params);
  }

  public forceDeleteAssets$(assetIds: number[]): Observable<any> {
    const params = new HttpParams().appendAll({
      'asset_ids': assetIds,
      'force': true
    });
    return this.postDeleteAssets(params);
  }

  public deleteProjects$(projectIds: number[]): Observable<any> {
    const restServiceUrl = this.apiUrl + 'delete_projects/';
    const params = new HttpParams().appendAll({
      'project_ids': projectIds
    });
    return this.http.post<ChangeFolderResponseModel>(restServiceUrl, null, {
      headers: DesktopRestService.HEADERS,
      params: params
    });
  }

  private getRawAssets$(): Observable<AssetsResponseModel> {
    const restServiceUrl = this.apiUrl + 'get_assets/';
    const queryData = new FormData();
    return this.http.post<AssetsResponseModel>(restServiceUrl, queryData);
  }

  public getAssetsAsTiwpItems$( alsoForbiddenAccess = false ): Observable<TiwpItem[]> {
    const getAssetTypeImage = (assetType: TypeModel): string => {
      let image: string;
      const assetTypeId = assetType?.id;
      if (assetTypeId) {
        image = env_const.default.assetTypeIcon[assetTypeId];
        if (!image && assetType.icon) {
          return getServerUrl() + assetType.icon;
        }
      }

      if (!image) {
        return getServerUrl() + '/' + env_const.default.assetImg;
      }

      return image;
    };
    return this.getRawAssets$().pipe(
      map((response) => {
        if (response && response.success && response.assets) {
          const assetList = response.assets.map((asset) => {
            asset.view = DesktopRestService.getAssetViewer(asset, this._assetTypes, env_const.panoramaAssetTypeIds);
            asset.type = DesktopRestService.getAssetType(asset, this._assetTypes);
            if ( alsoForbiddenAccess && !asset.type ) {
              asset.type = asset.asset_type_id;
            }
            asset.asset_type_id = asset.type;

            let image;
            const fileExtension = DesktopRestService.getFileExtension(asset.file);
            if (asset.view !== Viewers.panorama &&
              (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'svg')) {
              // Type id: 3 name: "Imagen"
              image = asset.file;
            } else {
              image = getAssetTypeImage(asset.type);
            }

            return <TiwpItem>{
              // id: asset.id,
              name: asset.name,
              // file: asset.file,
              filterKey: asset.asset_type_id?.i18n_key || 'forbidden access',
              uiImage: image,
              isOwner: this.store.isOwner(asset.user_id),
              video: (asset.view === Viewers.video),
              asset: asset
            };
          });
          const allowedAssets = assetList.filter((item) => item.asset.type);
          return allowedAssets.sort((a, b) => a.name.localeCompare(b.name));
          /*
                    return assetList
                      .filter( (item) => item.asset.type )
                      .sort((a, b) => a.name.localeCompare(b.name));
          */
          /*
                    return assetList.sort(function(a, b) {
                      const nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                      if (nameA < nameB) { // sort string ascending
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      return 0; // default return value (no sorting)
                    });
          */
        } else {
          throw new Error((response && response.errors) || 'Error: Invalid assets query');
        }
      })
    );
  }

  private getRawProjects$(): Observable<ProjectsResponseModel> {
    const restServiceUrl = this.apiUrl + 'get_projects/';
    const queryData = new FormData();
    queryData.append('external_platform', 'false');

    return this.http.post<ProjectsResponseModel>(restServiceUrl, queryData);
  }

  public getProject$(projectId: number): Observable<ProjectResponseModel> {
    if (projectId !== undefined) {
      const restServiceUrl = this.apiUrl + 'get_project/';
      const queryData = new FormData();
      queryData.append('project_id', projectId.toString());

      return this.http.post<ProjectResponseModel>(restServiceUrl, queryData);
    }
  }

  public getProjectsAsTiwpItems$(): Observable<TiwpItem[]> {
    return this.getRawProjects$().pipe(
      map((responseData) => {
        if (responseData && responseData.success) {
          const projectList = responseData.projects.map((project) => {
            return {
              name: project.name,
              filterKey: project.project_type_id.i18n_key,
              // uiImage:  DesktopRestService.getProjectTypeImage (this._projectTypes, project.project_type_id.id),
              uiImage: DesktopRestService.getProjectTypeIcon(project.project_type_id.id),
              isOwner: this.store.isOwner(project.user_id),
              project: project
            };
          });
          return projectList.sort((a, b) => a.name.localeCompare(b.name));
          /*
                    return projectList.sort(function(a, b) {
                      const nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                      if (nameA < nameB) { // sort string ascending
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      return 0; // default return value (no sorting)
                    });
          */
        } else {
          throw new Error((responseData && responseData.errors) || 'Error: Invalid projects query');
        }
      })
    );
  }

  public createProject$(projectTypeId: number, name: string, folderId: number, accessibilityTypeId?: number): Observable<ProjectResponseModel> {
    if (projectTypeId !== null && projectTypeId !== undefined && name) {
      const stateId = env_const.default.onNewProject.stateId;
      const sceneTypeId = env_const.default.onNewProject.sceneId[projectTypeId];
      const restServiceUrl = this.apiUrl + 'create_project/';
      const queryData = new FormData();
      queryData.append('project_type_id', projectTypeId.toString());
      queryData.append('name', name);
      secureQueryNumberAppend(queryData, 'state_id', stateId);
      secureQueryNumberAppend(queryData, 'scene_type_id', sceneTypeId);
      secureQueryNumberAppend(queryData, 'folder_id', folderId);
      secureQueryNumberAppend(queryData, 'accessibility_type_id', accessibilityTypeId);

      return this.http.post<ProjectResponseModel>(restServiceUrl, queryData);
    }
  }

  public setProjectScenesOrder$(projectId: number, sceneIds: number[]): Observable<ProjectScenesResponseModel> {
    if (projectId !== null && projectId !== undefined) {
      const restServiceUrl = this.apiUrl + 'edit_project_scenes_order/';
      const params = new HttpParams().appendAll({
        'project_id': projectId,
        'scene_ids': sceneIds
      });
      return this.http.post<ProjectScenesResponseModel>(restServiceUrl, null, {
        headers: DesktopRestService.HEADERS,
        params: params
      });
    }
  }

  public setProjectJsonDescriptorUrl$(projectId: number, jsonDescriptor: string): Observable<any> {
    if (projectId !== null && projectId !== undefined) {
      const restServiceUrl = this.apiUrl + 'edit_project/';
      const queryData = new FormData();
      queryData.append('project_id', projectId.toString());
      queryData.append('urlJsonDescriptor', jsonDescriptor);

      return this.http.post<any>(restServiceUrl, queryData);
    }
  }

  public getProjectScenesWithServerComponents$(projectId: number): Observable<any> {
    if (projectId !== null && projectId !== undefined) {
      const restServiceUrl = this.apiUrl + 'get_project_scenes/';
      const queryData = new FormData();
      queryData.append('project_id', projectId.toString());

      const new_version = true;
      queryData.append('new_version', new_version.toString());

      return this.http.post<any>(restServiceUrl, queryData);
    }
  }

  public getSceneWithServerComponents$(sceneId: number): Observable<SceneResponseModel> {
    if (sceneId !== null && sceneId !== undefined) {
      const restServiceUrl = this.apiUrl + 'get_scene/';
      const queryData = new FormData();
      queryData.append('scene_id', sceneId.toString());

      const new_version = true;
      queryData.append('new_version', new_version.toString());

      return this.http.post<SceneResponseModel>(restServiceUrl, queryData);
    }
  }

  public getScene$(sceneId: number): Observable<SceneResponseModel> {
    return this.getSceneWithServerComponents$(sceneId).pipe(
      map((response) => {
        const updatedResponse = <SceneResponseModel>{
          success: response.success,
          errors: response.errors,
          scene: undefined
        };

        if (response && response.success && response.scene) {
          updatedResponse.scene = this.componentsTranslator.translateSceneObjectsFromSeverSideToClientSide(response.scene);
        }

        return updatedResponse;
      })
    );
  }

  public getAvailableSceneTypesInProject$(projectId: number): Observable<SceneTypesResponseModel> {
    if (projectId !== null && projectId !== undefined) {
      const restServiceUrl = this.apiUrl + 'get_scene_types/';
      const queryData = new FormData();
      queryData.append('project_id', projectId.toString());

      return this.http.post<SceneTypesResponseModel>(restServiceUrl, queryData);
    }
  }

  /*
    public old_addSceneObject$( sceneId: number, objectTypeId: number, name: string, components?: any, assetId?: number ): Observable<any> {
      if ( sceneId !== null && sceneId !== undefined && objectTypeId !== null && objectTypeId !== undefined && name ) {
        const restServiceUrl = this.apiUrl + 'add_object/';
        const queryData = new FormData();
        queryData.append('scene_id', sceneId.toString() );
        queryData.append('object_type_id', objectTypeId.toString() );
        queryData.append('name', name );
        secureQueryNumberAppend ( queryData, 'asset_id', assetId );
        secureQueryObjectAppend ( queryData, 'components', components );

        return this.http.post<any>(restServiceUrl, queryData);
      }
    }
  */

  private addSceneObjectWithServerComponents$(
    sceneId: number, objectTypeId: number, name: string, components?: ComponentsModel,
    assetId?: number, parentId?: number, active?: boolean, visible?: boolean
  ): Observable<ObjectResponseModel> {
    if (sceneId !== null && sceneId !== undefined && objectTypeId !== null && objectTypeId !== undefined && name) {
      const restServiceUrl = this.apiUrl + 'add_object/';
      const queryData = new FormData();
      queryData.append('scene_id', sceneId.toString());
      queryData.append('object_type_id', objectTypeId.toString());
      queryData.append('name', name);

      secureQueryNumberAppend(queryData, 'parent_id', parentId);
      secureQueryNumberAppend(queryData, 'asset_id', assetId);
      secureQueryBooleanAppend(queryData, 'active', active);
      secureQueryBooleanAppend(queryData, 'visible', visible);

      if (components && isFilled(components)) {
        const objectComponentTypes = this.store.getObjectComponentTypes(objectTypeId);
        if (objectComponentTypes) {
          const serverComponentsToSend = this.componentsTranslator.getServerSideComponents(components, objectComponentTypes);
          secureQueryObjectAppend(queryData, 'components', serverComponentsToSend);
        }
      }

      const new_version = true;
      queryData.append('new_version', new_version.toString());

      return this.http.post<any>(restServiceUrl, queryData);
    }
  }

  public addSceneObject$(
    sceneId: number, objectTypeId: number, name: string, components?: ComponentsModel,
    assetId?: number, parentId?: number, active?: boolean, visible?: boolean
  ): Observable<ObjectResponseModel> {
    return this.addSceneObjectWithServerComponents$(sceneId, objectTypeId, name, components, assetId, parentId, active, visible).pipe(
      map((response) => {
        const updatedResponse = <ObjectResponseModel>{
          success: response.success,
          errors: response.errors,
          object: undefined
        };

        if (response && response.success && response.object) {
          updatedResponse.object = this.componentsTranslator.translateObjectFromSeverSideToClientSide(response.object);
        }

        return updatedResponse;
      })
    );
  }

  private editSceneObjectWithServerComponents$(object: TiwpSceneObjectModel, dataToEdit: ObjectEditModel): Observable<ObjectResponseModel> {
    if (object && dataToEdit) {
      const restServiceUrl = this.apiUrl + 'edit_object/';
      const queryData = new FormData();
      queryData.append('object_id', object.id.toString());
      secureQueryNumberAppend(queryData, 'asset_id', dataToEdit.assetId);
      secureQueryStringAppend(queryData, 'name', dataToEdit.name);
      secureQueryBooleanAppend(queryData, 'active', dataToEdit.active);
      secureQueryBooleanAppend(queryData, 'visible', dataToEdit.visible);
      secureQueryNumberAppend(queryData, 'parent_id', dataToEdit.parentId);
      secureQueryNumberAppend(queryData, 'object_type_id', dataToEdit.typeId);

      if (dataToEdit.components && isFilled(dataToEdit.components)) {
        // const objectComponentTypes = this.store.getObjectComponentTypes( object.id );
        const objectComponentTypes = object.object_type_id.component_types;
        if (objectComponentTypes) {
          const serverComponentsToSend = this.componentsTranslator.getServerSideComponents(dataToEdit.components, objectComponentTypes);
          secureQueryObjectAppend(queryData, 'components', serverComponentsToSend);
        }
      }
      const new_version = true;
      queryData.append('new_version', new_version.toString());

      return this.http.post<any>(restServiceUrl, queryData);
    }
  }

  public editSceneObject$(object: TiwpSceneObjectModel, dataToEdit: ObjectEditModel): Observable<ObjectResponseModel> {
    return this.editSceneObjectWithServerComponents$(object, dataToEdit).pipe(
      map((response) => {
        const updatedResponse = <ObjectResponseModel>{
          success: response.success,
          errors: response.errors,
          object: undefined
        };

        if (response && response.success && response.object) {
          updatedResponse.object = this.componentsTranslator.translateObjectFromSeverSideToClientSide(response.object);
        }

        return updatedResponse;
      })
    );
  }

  public deleteSceneObject$(objectId: number): Observable<any> {
    if (objectId !== null && objectId !== undefined) {
      const restServiceUrl = this.apiUrl + 'delete_object/';
      const queryData = new FormData();
      queryData.append('object_id', objectId.toString());

      return this.http.post<any>(restServiceUrl, queryData);
    }
  }

  public createScene$(projectId: number, sceneTypeId: number, name: string, num_order?: number): Observable<SceneResponseModel> {
    if (projectId !== null && projectId !== undefined && sceneTypeId !== null && sceneTypeId !== undefined && name) {
      const restServiceUrl = this.apiUrl + 'create_scene/';
      const queryData = new FormData();
      queryData.append('project_id', projectId.toString());
      queryData.append('scene_type_id', sceneTypeId.toString());
      queryData.append('name', name);
      secureQueryNumberAppend(queryData, 'num_order', num_order);

      return this.http.post<SceneResponseModel>(restServiceUrl, queryData);
    }
  }

  public cloneScene$WithServerComponents$(projectId: number, sceneId: number, name: string): Observable<SceneResponseModel> {
    if (projectId !== null && projectId !== undefined && sceneId !== null && sceneId !== undefined && name) {
      const restServiceUrl = this.apiUrl + 'clone_scene/';
      const queryData = new FormData();
      queryData.append('project_id', projectId.toString());
      queryData.append('scene_id', sceneId.toString());
      queryData.append('name', name);

      return this.http.post<any>(restServiceUrl, queryData);
    }
  }

  public cloneScene$(projectId: number, sceneId: number, name: string): Observable<SceneResponseModel> {
    return this.cloneScene$WithServerComponents$(projectId, sceneId, name).pipe(
      map((response) => {
        const updatedResponse = <SceneResponseModel>{
          success: response.success,
          errors: response.errors,
          scene: undefined
        };

        if (response && response.success && response.scene) {
          updatedResponse.scene = this.componentsTranslator.translateSceneObjectsFromSeverSideToClientSide(response.scene);
        }

        return updatedResponse;
      })
    );
  }

  public renameScene$(sceneId: number, name: string): Observable<SceneResponseModel> {
    if (sceneId !== null && sceneId !== undefined && name) {
      const restServiceUrl = this.apiUrl + 'rename_scene/';
      const queryData = new FormData();
      queryData.append('scene_id', sceneId.toString());
      queryData.append('name', name);

      return this.http.post<SceneResponseModel>(restServiceUrl, queryData);
    }
  }


  public deleteScene$(sceneId: number): Observable<SceneResponseModel> {
    if (sceneId !== null) {
      const restServiceUrl = this.apiUrl + 'delete_scene/';
      const queryData = new FormData();
      queryData.append('scene_id', sceneId.toString());

      return this.http.post<SceneResponseModel>(restServiceUrl, queryData);
    }
  }

  public cloneAsset$(assetId: number, nameToUse: string, folderId?: number): Observable<any> {
    const restServiceUrl = this.apiUrl + 'clone_asset/';
    const queryData = new FormData();
    queryData.append('asset_id', assetId.toString());
    if (nameToUse) {
      queryData.append('name', nameToUse);
    }

    secureQueryNumberAppend(queryData, 'folder_id', folderId);

    return this.http.post<any>(restServiceUrl, queryData);
  }

  public uploadFile$(
    file: File, 
    assetTypeId: number,
    folderId?: number,
    withVideoAssetConversion?: boolean,
    asset_id_to_update?: number
  ): Observable<any> {
    // https://malcoded.com/posts/angular-file-upload-component-with-express/
    // https://www.techiediaries.com/angular-file-upload-progress-bar/
    const restServiceUrl = this.apiUrl + 'send_file/';

    // create a new multipart-form for every file
    const queryData: FormData = new FormData();
    queryData.append('file', file);

    if (!secureQueryNumberAppend(queryData, 'asset_id_to_update', asset_id_to_update)) {
      queryData.append('asset_type_id', assetTypeId.toString());
    }

    if (withVideoAssetConversion) {
      queryData.append('withVideoAssetConversion', withVideoAssetConversion.toString());
    }

    secureQueryNumberAppend(queryData, 'folder_id', folderId);

    // https://stackoverflow.com/questions/62235319/reportprogress-is-not-working-in-production/62257571#62257571
    const headers = new HttpHeaders({'ngsw-bypass': ''});
    return this.http.post<any>(restServiceUrl, queryData, {
      reportProgress: true,
      observe: 'events',
      headers: headers
    });
  }

  public getAssetConversionProgress$(assetId: number): Observable<any> {
    if (assetId !== null) {
      const restServiceUrl = this.apiUrl + 'get_asset_progress/';
      const queryData = new FormData();
      queryData.append('asset_id', assetId.toString());

      return this.http.post<any>(restServiceUrl, queryData);
    }
  }

  public getUserCapability$(): Observable<UserCapabilityResponseModel> {
    const restServiceUrl = this.apiUrl + 'get_user_capability/';
    const queryData = new FormData();

    return this.http.post<UserCapabilityResponseModel>(restServiceUrl, queryData);
  }

  public getUsers$(): Observable<UsersResponseModel> {
    const restServiceUrl = this.apiUrl + 'get_users/';
    const queryData = new FormData();

    return this.http.post<UsersResponseModel>(restServiceUrl, queryData);
  }

  public setUserRole$(userId: number, roleId: number): Observable<UserResponseModel> {
    if (userId !== null && userId !== undefined &&
      roleId !== null && roleId !== undefined) {
      const restServiceUrl = this.apiUrl + 'set_user_role/';
      const queryData = new FormData();
      queryData.append('user_id', userId.toString());
      queryData.append('role_id', roleId.toString());

      return this.http.post<UserResponseModel>(restServiceUrl, queryData);
    }
  }

  public createUser$(newUser: NewUser): Observable<any> {
    const restServiceUrl = this.apiUrl + 'create_user/';

    // create a new multipart-form for every file
    const queryData: FormData = new FormData();
    queryData.append('user_name', newUser.username);
    queryData.append('user_password', newUser.password);
    queryData.append('role_id', newUser.roleId.toString());

    secureQueryStringAppend(queryData, 'email', newUser.email);
    secureQueryStringAppend(queryData, 'first_name', newUser.firstname);
    secureQueryStringAppend(queryData, 'last_name', newUser.lastname);
    secureQueryStringAppend(queryData, 'nick', newUser.nick);

    let options;
    if (newUser.imageFile) {
      queryData.append('user_image', newUser.imageFile);
      // https://stackoverflow.com/questions/62235319/reportprogress-is-not-working-in-production/62257571#62257571
      const headers = new HttpHeaders({'ngsw-bypass': ''});
      options = {
        reportProgress: true,
        observe: 'events',
        headers: headers
      };
    }
    return this.http.post<any>(restServiceUrl, queryData, options);
  }

  public editUserInfo$(
    userId: number,
    imgFile?: File,
    firstname?: string,
    lastname?: string,
    email?: string
  ): Observable<any> {
    const restServiceUrl = this.apiUrl + 'edit_user/';

    // create a new multipart-form for every file
    const queryData: FormData = new FormData();
    queryData.append('user_id', userId.toString());

    secureQueryStringAppend(queryData, 'email', email);
    secureQueryStringAppend(queryData, 'first_name', firstname);
    secureQueryStringAppend(queryData, 'last_name', lastname);

    let options;
    if (imgFile !== undefined && imgFile !== null) {
      queryData.append('user_image', imgFile);
      // https://stackoverflow.com/questions/62235319/reportprogress-is-not-working-in-production/62257571#62257571
      const headers = new HttpHeaders({'ngsw-bypass': ''});
      options = {
        reportProgress: true,
        observe: 'events',
        headers: headers
      };
    }
    return this.http.post<any>(restServiceUrl, queryData, options);
  }

  // Service to call external url and receive plain text response
  public proxyRequest$(url: string): Observable<any> {
    const restServiceUrl = this.apiUrl + 'proxy_request/';
    const queryData = new FormData();
    queryData.set('url', url);

    return this.http.post(restServiceUrl, queryData, {responseType: 'text' as const});
  }
}
