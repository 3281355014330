import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { TranslateModule } from '@ngx-translate/core';

import { PlayDialogComponent } from '@common/dialogs/play-dialog/play-dialog.component';
import { SpinnerDialogComponent } from '@common/dialogs/spinner-dialog/spinner-dialog.component';
import { ConfirmationDialogComponent } from '@common/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MessageDialogComponent } from '@common/dialogs/message-dialog/message-dialog.component';
import { ItemSelectionDialogComponent } from '@common/dialogs/item-selection-dialog/item-selection-dialog.component';
import { FolderSelectionDialogComponent } from '@common/dialogs/folder-selection-dialog/folder-selection-dialog.component';
import { PublicLinkDialogComponent } from '@common/dialogs/public-link-dialog/public-link-dialog.component';

import { CustomMaterialModule } from '@common/material.module';
import { CommonDialogsService } from '@common/dialogs/common-dialogs.service';
import { SelectListDialogComponent } from '@common/dialogs/select-list-dialog/select-list-dialog.component';
import { SortItemsDialogComponent } from '@common/dialogs/sort-items-dialog/sort-items-dialog.component';
import { CheckUserDialogComponent } from '@common/dialogs/check-user-dialog/check-user-dialog.component';
import { ChangeUserPwdDialogComponent } from '@common/dialogs/change-user-pwd-dialog/change-user-pwd-dialog.component';

import { ItemTreeModule } from '@components/item-tree/item-tree.module';

import { QrCodeModule } from 'ng-qrcode';

@NgModule({
    declarations: [
        FolderSelectionDialogComponent,
        PlayDialogComponent,
        ChangeUserPwdDialogComponent,
        CheckUserDialogComponent,
        MessageDialogComponent,
        ConfirmationDialogComponent,
        PublicLinkDialogComponent,
        SpinnerDialogComponent,
        ItemSelectionDialogComponent,
        SortItemsDialogComponent,
        SelectListDialogComponent
    ],
    imports: [
        QrCodeModule,
        FormsModule,
        BrowserModule /*CommonModule*/,
        CustomMaterialModule,
        DragDropModule,
        ReactiveFormsModule,
        TranslateModule.forChild({
            extend: true
        }),
        ItemTreeModule
    ],
    exports: [
        PlayDialogComponent,
        ChangeUserPwdDialogComponent,
        CheckUserDialogComponent,
        MessageDialogComponent,
        ConfirmationDialogComponent,
        PublicLinkDialogComponent,
        SpinnerDialogComponent,
        CustomMaterialModule,
        ItemSelectionDialogComponent,
        SortItemsDialogComponent,
        SelectListDialogComponent
    ],
    providers: [
        CommonDialogsService
    ]
})
export class CommonDialogsModule { }
