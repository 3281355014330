import {Component, OnInit, OnDestroy} from '@angular/core';
import {environment as env_const} from '@env/environment';
import {Router} from '@angular/router';
import {AdminRestService} from '@views/admin/admin-rest.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MustMatchValidator} from '@components/user-cards/new-user-card/must-match-validator';
import {CommonDialogsService} from '@common/dialogs/common-dialogs.service';
import {OrganizationCreationResponseModel} from '@common/models/response-model';
import {TranslateService} from '@ngx-translate/core';
import {TypeModel} from '@common/models/id-name-model';
import {StoreService} from '@common/services/store.service';
import {DesktopRestService} from '@common/services/rest/desktop-rest-service/desktop-rest.service';
import {SelectionType} from '@common/models/types-model';

@Component({
  selector: 'tiwp-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  assetTypes: SelectionType[];
  selectedAssetTypes: number[];

  projectTypes: SelectionType[];
  selectedProjectTypes: number[];

  assetTypesControl = new UntypedFormControl('');
  projectTypesControl = new UntypedFormControl('');

  public _organizationName: string;
  public _organizationShortName: string;
  public _username: string;
  public _password: string;
  public _passwordConfirmation: string;
  public _email: string;
  public _projects: string;
  public _assets: string;

  formController: UntypedFormGroup;
  get formControls() {
    return this.formController.controls;
  }

  private assetTypesSubscription;
  private projectTypesSubscription;

  private static getItemField( items: SelectionType[], field: string, id: number ): string {
    const foundItem = items.find( (item) => item.id === id );
    return foundItem && foundItem[field] ? foundItem[field] : '';
  }

  private static sortByName (arrayList: TypeModel[]): TypeModel[] {
    return arrayList.sort(
      (a, b) => a.name.localeCompare(b.name)
    );
  }

  constructor(
    private store: StoreService,
    private commonDialog: CommonDialogsService,
    private adminRestService: AdminRestService,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) { }

  getAssetTypeName( itemId: number ): string {
    return AdminComponent.getItemField( this.assetTypes, 'name', itemId );
  }

  getAssetTypeIcon( itemId: number ): string {
    return AdminComponent.getItemField( this.assetTypes, 'uiImage', itemId );
  }

  getProjectTypeName( itemId: number ): string {
    return AdminComponent.getItemField( this.projectTypes, 'name', itemId );
  }

  getProjectTypeIcon( itemId: number ): string {
    return AdminComponent.getItemField( this.projectTypes, 'uiImage', itemId );
  }

  ngOnInit(): void {
    this.assetTypesSubscription = this.store.getAssetTypes$().subscribe(this.setAssetTypes.bind(this));
    this.projectTypesSubscription = this.store.getProjectTypes$().subscribe(this.setProjectTypes.bind(this));

    this._organizationName = '';
    this._organizationShortName = '';
    this._username = '';
    this._email = '';
    this._password = '';
    this._passwordConfirmation = '';
    this._projects = '';
    this._assets = '';

    // idListValidator: number at least one time plus any time of comma continued by a number
    // url to test RegExp: https://regex101.com
    const idListValidator = Validators.pattern('(?:[0-9]+)+(?:,[0-9]+)*' );

    // const passwordValidator = Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{8,}$' );
    // al menos:
    //          una letra minuscula (?=.*[a-z]),
    //          una letra mayuscula (?=.*[A-Z]),
    //          un número (?=.*\d),
    //          un caracter diferente a letra o digito (?=.*\W),
    //          siendo un grupo de entre 8-10 caracteres de letras, digitos o resto de caracteres: [a-zA-Z\d\W]{8,}
    // const passwordValidator = Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{8,}$' );
    this.formController = this.formBuilder.group({
      organizationName: [this._organizationName, [
        Validators.required
      ]],
      organizationShortName: [this._organizationShortName, [
        Validators.required
      ]],
      username: [this._username, [
        Validators.required,
        Validators.minLength(5)
      ]],
      password: [this._password, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(10),
        // at least one letter and one number, been une item of [letters, numbers or rest chars]:
        Validators.pattern('^(?=.*[a-z])(?=.*\\d)[a-zA-Z\\d\\W]{1,}$')
        // at least one letter and one number, o a group of 8 to 10 chars of letters, numbers or rest chars:
        // Validators.pattern('^(?=.*[a-z])(?=.*\\d)[a-zA-Z\\d\\W]{8,10}$')
      ]],
      confirmPassword: [this._passwordConfirmation, [
        Validators.required/*,
        PasswordUnmatchedValidator('password')*/
      ]],
      email: [this._email, [
        Validators.email,
        Validators.pattern('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])' )
      ]],
      projects: [this._projects, [
        idListValidator
      ]],
      assets: [this._assets, [
        idListValidator
      ]],
    }, {
      validator: MustMatchValidator('password', 'confirmPassword')
    } );
  }

  ngOnDestroy() {
    this.assetTypesSubscription.unsubscribe();
    this.projectTypesSubscription.unsubscribe();
  }

  private setAssetTypes(assetTypes: TypeModel[]) {
    assetTypes = assetTypes.filter(assetTpe => !assetTpe.i18n_key.includes('Editora'));
    assetTypes = AdminComponent.sortByName( assetTypes );
    this.assetTypes = assetTypes.map( (assetType) => {
      return {
        id: assetType.id,
        name: assetType.name,
        uiImage: DesktopRestService.getAssetTypeImage(assetTypes, assetType.id),
        type: assetType
      };
    });
    // this.selectedAssetTypes = [this.assetTypes[0].id];
  }

  private setProjectTypes(projectTypes: TypeModel[]) {
    projectTypes = AdminComponent.sortByName( projectTypes );
    this.projectTypes = projectTypes.map((projectType) => {
      return {
        id: projectType.id,
        name: projectType.name,
        // uiImage: DesktopRestService.getProjectTypeImage (projectTypes, projectType.id),
        uiImage: DesktopRestService.getProjectTypeIcon(projectType.id),
        type: projectType
      };
    });
    // this.selectedProjectTypes = [this.projectTypes[0].id];
  }

  private showError( error, messageErrorKey: string ) {
    this.commonDialog.hideSpinner();
    const message = this.translate.instant(messageErrorKey) || 'ERROR';
    this.commonDialog.openMessageDialog$( message );
    console.error( 'Error: ', error );
  }

  private onOrganizationCreationNotSuccess( errors: string ) {
    let messageErrorKey = 'admin.newOrgError';
    if ( errors ) {
      if ( errors.includes('Duplicate Organization name') ) {
        messageErrorKey = 'admin.newOrgRepeatName';
      } else if ( errors.includes('Duplicate username') ) {
        messageErrorKey = 'admin.newOrgRepeatUsername';
      }
    }
    this.showError( errors, messageErrorKey );
  }

  createNewOrganization() {
    this.commonDialog.showSpinner( this.translate.instant('admin.creatingOrg') );
    this.adminRestService.createOrganization$( {
      name: this._organizationName,
      short_name: this._organizationShortName,
      admin_username: this._username,
      admin_password: this._password,
      email: this._email
    } ).subscribe(
      ( response: OrganizationCreationResponseModel ) => {
        if ( response.success ) {
          this.afterOrganizationCreated( response.organization.id, response.user );
        } else {
          this.onOrganizationCreationNotSuccess( response.errors );
        }
      },
      ( response ) => this.showError( response.error, 'admin.newOrgError')
    );
  }

  private afterOrganizationCreated( newOrganizationID: number, newUserID: number ) {
    this.commonDialog.hideSpinner();
    this.setupOrganizationTypes( newOrganizationID, newUserID );
  }

  private setupOrganizationTypes( newOrganizationID: number, newUserID: number ) {
    this.commonDialog.showSpinner( this.translate.instant('admin.assignOrgTypes') );
    this.adminRestService.setupOrganizationTypes$(newOrganizationID, this.selectedAssetTypes, this.selectedProjectTypes).subscribe(
      (response ) => {
        if ( response.success ) {
          this.afterOrganizationTypesAssignation( newOrganizationID, newUserID );
        } else {
          this.showError( response.errors, 'admin.assignOrgTypesError' );
        }
      },
      ( response ) => this.showError( response.error, 'admin.assignOrgTypesError')
    );
  }

  private afterOrganizationTypesAssignation( newOrganizationID: number, newUserID: number ) {
    this.commonDialog.hideSpinner();
    if ( this._projects ) {
      this.cloneProjects( newOrganizationID, newUserID );
    } else if ( this._assets ) {
      this.cloneAssets( newOrganizationID, newUserID );
    } else {
      this.commonDialog.openTranslatedMessageDialog$('admin.newOrgSuccess');
    }
  }

  private cloneEndSuccessfully() {
    this.commonDialog.hideSpinner();
    this.commonDialog.openTranslatedMessageDialog$('admin.newOrgCloningSuccess');
  }

  cloneProjects( organizationID: number, userID: number ) {
    this.commonDialog.showSpinner( this.translate.instant('admin.cloningProjects') );
    const projectStringIds: string[] = this._projects.split(',');
    const projectIds: number[] = projectStringIds.map ( ( stringID: string ) => parseInt(stringID, 10) );
    this.adminRestService.cloneProjects$( organizationID, userID, projectIds ).subscribe(
      ( response ) => {
        if ( response.success ) {
          if ( this._assets ) {
            this.cloneAssets( organizationID, userID );
          } else {
            this.cloneEndSuccessfully();
          }
        } else {
          this.showError( response.errors, 'admin.projectsCloneError' );
        }
      },
      ( response ) => this.showError( response.error, 'admin.projectsCloneError' )
    );
  }

  cloneAssets( organizationID: number, userID: number ) {
    this.commonDialog.showSpinner( this.translate.instant('admin.cloningAssets') );
    const assetStringIds: string[] = this._assets.split(',');
    const assetIds: number[] = assetStringIds.map ( ( stringID: string ) => parseInt(stringID, 10) );
    this.adminRestService.cloneAssets$( organizationID, userID, assetIds ).subscribe(
      (response ) => {
        if ( response.success ) {
          this.cloneEndSuccessfully();
        } else {
          this.showError( response.errors, 'admin.assetsCloneError' );
        }
      },
      ( response ) => this.showError( response.error, 'admin.assetsCloneError' )
    );
  }

  navToLogin() {
    this.router.navigateByUrl(env_const.loginUrl);
    // clone In Tecnalia Edusuko
    // this.afterOrganizationCreated( 2, 8 );
  }

  onUsernameChange( newUsername: string ) {
    this._username = newUsername ? newUsername.trim().toLowerCase() : '';
  }
}
